@import 'reset';

@import 'account-button';

$lightgreen: rgb(230, 252, 228);
$green: rgb(196, 249, 190);

*:focus {
  outline: none;
}

body {
  font-family: "Open Sans";
  margin-top: 20px;
  display: none;
}

h1 {
  text-align: center;
  font-size: 48px;
  font-weight: 300;
  color: black;
  padding-bottom: 20px;
}

input#newTopic {
  border: 0;
  font-size: 20px;
  border-bottom: 1px solid green;
  margin-right: 10px;
  width: 630px;
  padding: 0px 5px;
}

#add-topic {
  font-size: 20px;
  padding: 10px 15px;
  border: 1px solid green;
  text-decoration: none;
  color: black;
  position: relative;
  bottom: 14px;

  &:hover {
    background: $lightgreen;
    color: darkgreen;
  }
}

.green {
  color: green;
}

.title {
  padding-bottom: 50px;
}

.main-list {
  font-size: 130%;
  width: 800px;
  margin: 0 auto;
  line-height: 1.2;
}

.main-list li {
  margin-bottom: 10px;
}

.main-list li a {
  border-radius: 3px;
  border: 1px solid gray;
  display: block;
  padding: 10px;
  text-decoration: none;
  transition: background-color 100ms linear;
}

.main-list li a:hover, .main-list li a.hovered {
  border: 1px solid green;
  text-decoration: none;
  background-color: $lightgreen;
  transition: background-color 100ms linear;
}
