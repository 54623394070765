#account-menu {
  position: fixed;
  border: 1px solid darkgray;
  right: 0px;
  top: 0px;
  margin: 10px;
  cursor: default;
  transition: all 500ms linear;
  line-height: 30px;
  max-height: 30px + 16px;
  overflow: hidden;
  background: white;
  z-index: 2;

  #google-signin-button {
    margin: 0 auto 18px;
    width: 125px;
  }

  input {
    font-size: 18px;
    min-width: 40px;
    padding: 0 4px;
    box-sizing: border-box;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px dotted lightgray;
  }

  a, .account-menu-head {
    padding: 9px;
    text-decoration: none;
    text-align: center;
    display: block;
    user-select: none;

    &.section {
      text-align: left;
      padding: 0 9px;
      border-bottom: 1px solid lightgray;
      border-top: 1px solid lightgray;

      .option {
        padding: 5px 0;
        select {
          border:0; zoom: 150%;
        }
      }
    }

    .section-title {
      margin: -1px 0 -2px -4px;
      padding: 3px 1px;
      font-size: 21px;
      // text-align: center;
    }
  }

  &:hover, &.hovered {
    transition: all 100ms linear;
    max-height: 300px;
  }

  &.hidden {
    max-width: 0px;
  }

  .account-menu-item {
    margin: 0;
  }
}
